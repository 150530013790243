import React from 'react';
import './App.css';
import Layout from '../src/Layout'
import Home from './Component/Home';
import VideoPopUp from './Component/VideoPopUp';
import CaseStudy from './Component/CaseStudyIndex';
import CaseStudyDetails from './Component/CaseStudyDetails'
import SolutionsSupplyChainSoftware from './Component/Solutions-Supply-Chain-Software';
import FoodIndustrySoftware from './Component/FoodIndustrySoftware'
import Contractormanagement  from'./Component/Contractor-management';
import ProductManagement from './Component/ProductManagement';
import SupplierPerformance from './Component/SupplierPerformance'
import Etender from './Component/eTender';
import ContactUS from './Component/ContactUs';
import ContactManagement from './Component/Contract-management';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReverseAuction from './Component/reverse-auction-eauction';
import Insights from './Component/Insights'
import BlogDetails from './Component/BlogDetails'
import AboutUs from './Component/AboutUs'
import Privacy from './Component/Privacy'
import SourceManagement from './Component/SourceManagement'
import PageBuilder from './Component/PageBuilder'
import useSendinblue from './hooks/useSendinblue';

function App() {
    const { REACT_APP_SIB_CLIENT_KEY } = process.env;
    const clientKey = REACT_APP_SIB_CLIENT_KEY;
    const trackingEmail = localStorage.getItem("trackingEmail"); 

    useSendinblue(clientKey, trackingEmail);
	return (
		<Router>
            <Layout>
            <div className="mainarea" >
                {/* <Route exact path="/" render={() => (
                    <Redirect to="/home" />
                )} /> */}

                <Routes>

                    <Route exact path='/' element={<Home/>} />
                    <Route exact path='/videopopup' element={<VideoPopUp/>} />
                    <Route autocapitalize="none" exact path='/case-study-index' element={<CaseStudy/>} />
                    <Route exact path='/supplier-management-platform' element={<SolutionsSupplyChainSoftware/>} />
                    <Route exact path='/supplier-information-management' element={<Contractormanagement/>} />
                    <Route exact path='/food-industry-software' element={<FoodIndustrySoftware/>} />
                    <Route exact path='/product-information-management' element={<ProductManagement/>} />
                    <Route exact path='/supplier-performance-management' element={<SupplierPerformance/>} />
                    <Route exact path='/etender' element={<Etender/>} />
                    <Route exact path='/page/:slug'element={<PageBuilder/>} />
                    <Route exact path='/about-us' element={<AboutUs/>} />
                    <Route exact path='/privacy' element={<Privacy/>} />
                    <Route exact path='/contact-us' element={<ContactUS/>} />
                    <Route exact path='/insights' element={<Insights/>} />
                    <Route exact path='/insights/:slug' element={<BlogDetails/>} />
                    <Route exact path='/contract-management' element={<ContactManagement/>} />
                    <Route exact path='/sourcing-management' element={<SourceManagement/>} />
                    <Route exact path='/reverse-auction-eauction' element={<ReverseAuction/>} />
                    <Route exact path='/case-study/:slug' element={<CaseStudyDetails/>} />
                    {/* <Route exact path='/pagebuilder/:slug' element={<LandingPage/>} /> */}
                    {/* <Route exact path='/reg/:slug' element={<RegistrationPage/>}/>
                    <Route exact path='/RegistrationSuccess' element={<RegistrationSuccess/>} /> */}
                    {/* <Route component={Home} >
                      <Redirect to="/" />
                    </Route> */}

                </Routes>
            </div>
            </Layout>
        </Router>
	);
}

export default App;
