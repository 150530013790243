import React, { useState} from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from "@apollo/client"
import ModalVideo from 'react-modal-video'
import BlogListing from '../BlogListing'
import CtaStripe from '../CtaStripe'
import {dateFormatter} from '../../Utils/dateFormatter'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import DocumentMeta from 'react-document-meta'
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader'
import FaqLists from '../FaqLists'

const BlogDetails = () => {
  const [isOpen, setOpen] = useState(false)
  const location = useLocation()
  const blogUrl = location.pathname
  const history = useNavigate()

  const faqMappingUrl = location.pathname

  const FaqListsQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `

  const currentBlogQuery = gql`
  query CurrentBlog ($url: String!){
    blogPostCollection(where:{slug: $url}){
      items{
        seoIndex
        blogPostProduct
        bodyRichtext
        {
          json
        }
        canonical
        description
        doesvideoexist
        estimatedreadtime
        heroImage{
          fileName
          url
          title
          description
        }
        metaDescription
        publishDate
        slug
        tags
        title
        titleTag
      }
    }
  }
  `
  const initialBlogQuery = gql`
  query InitialBlogs ($url: String!){
    blogPostCollection(where:{slug_not_contains:$url} order:publishDate_DESC limit:3){
      items{
       slug
        publishDate
        estimatedreadtime
        heroImage{
          url
          description
        }
        description
        title
      }
    }
  }
  `

  const { data:faqFetch } = useQuery(FaqListsQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  });


  const { data:currentBlgData } = useQuery(currentBlogQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? blogUrl.substr(blogUrl.lastIndexOf('/') + 1)
          : null,
    },
  })

  const { data:relatedBlgData } = useQuery(initialBlogQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? blogUrl.substr(blogUrl.lastIndexOf('/') + 1)
          : null,
    },
  })


  if(!currentBlgData || !relatedBlgData || !faqFetch) return(<Loader/>)


  const faqData = faqFetch?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqFetch?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items



  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

 let schema = mainEntity.length === 0 ? null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }



  const currentBlog = currentBlgData?.blogPostCollection?.items[0]

  const relatedBlg = relatedBlgData?.blogPostCollection

  const relatedBlogs = relatedBlg?.items.map(blg => {
          return {
            title: blg.title,
            date: blg.publishDate,
            shortDesc: blg.description,
            bloglink: blg.slug,
            imageSrc: blg.heroImage.url,
            altDesc:blg.heroImage.description,
            estimatedreadtime: blg.estimatedreadtime
          }})

  const {seoIndex, title, publishDate, estimatedreadtime, heroImage, doesvideoexist, blogpostvideolink, bodyRichtext, titleTag, metaDescription, canonical} = currentBlog || {}
  const formattedRichText = (typeof bodyRichtext !== 'undefined') ? documentToReactComponents(bodyRichtext.json) : ''
  const seoMeta = !seoIndex ? { robots: 'noindex, follow' } : {}
  const meta = {
    title: titleTag,
    description: metaDescription,
    canonical: canonical,
    meta: {
      charset: 'utf-8',
      name: seoMeta,
    }
  }
  const videoId = (typeof blogpostvideolink !== 'undefined') ? blogpostvideolink?.substr(blogpostvideolink.lastIndexOf('/') + 1) : ''
  const formattedDate = (typeof publishDate !== 'undefined') ? dateFormatter(publishDate) : publishDate
  const readTime = (typeof estimatedreadtime !== 'undefined') ? `Read Time: ${estimatedreadtime} minutes`: formattedDate
  const dataFetched = typeof currentBlog !== 'undefined'? 'render' :null

  return (
<>
{dataFetched === null && (history.push('/insights'))}
{dataFetched === 'render' && (<DocumentMeta {...meta}>
<div className="container mt-5 pt-md-5 pt-sm-3">
{schema && (
      <div
      dangerouslySetInnerHTML={{
        __html: `
          <script type="application/ld+json">${JSON.stringify(
            schema
          )}</script>
          `
         }}
      />
     )}
{/* Title */}
<p className="h1 blog-padd">Insight</p>
{/* Date/Time */}
<p className="clr">{readTime}</p>
<hr id="arcus" className="m-0 mb-2" />
<div className="row">
<div className="col-lg-8">
<h5 className="font-weight-bold">{title}</h5>
</div>
{/*More Insights starts: This will shown only in large viewports and hidden in all other devices (for responsive purpose)*/}
<div className="col-lg-4 text-center d-none d-lg-block">
<h5 className="font-weight-bolder">More Insights</h5>
</div>
{/*More Insights end:*/}
</div>
{/* Page Content */}
 <div className="row">
{/* Post Content Column */}
<div className="col-lg-8">
{/* Preview Image */}
<img className="img-fluid blogimg w-100 mt-4" src={heroImage?.url} alt={heroImage?.description} />
{/* Post Content */}
<div className="mt-5 clr">{formattedRichText}</div><br />
{doesvideoexist && (
<div className="text-center">
<ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
<button className="btn btn-secondary col-lg-4" onClick={()=> setOpen(true)}>Watch Insight Video</button>
 </div>
 )}
</div>
{/* Sidebar Widgets Column */}
 <div className="col-lg-4 text-center p-0">
{/* Search Widget */}
{/*More Insights starts: This will hidden only in large viewports and shown in all other devices (for responsive perpose)*/}
<div className="col-lg-4 text-center d-block d-lg-none">
<h5 className="font-weight-bolder my-4">More Insights</h5>
</div>
{/*More Insights end:*/}
{/* Side Widget */}
<BlogListing bloglist={relatedBlogs} view="mobile" />
</div>
</div>
{/* /.row */}
</div>
        {/* ==========================faqlist start============================ */}

        {faqData?<div className="col-lg-6 m-auto py-3">
          <FaqLists {...faqData}/>
        </div>:''}

        {/* ==========================faqlist end============================ */}


<CtaStripe bodyText="Read More Insights" buttonText="View Insights" buttonLink="/insights" />
</DocumentMeta>
)
}
</>
    )
}
export default BlogDetails
