import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css"
import ContactusForm from '../Component/ContactusForm'
import CtaStripe from '../Component/CtaStripe'
import DocumentMeta from 'react-document-meta'


const ContactUs = () =>{
  const meta = {
    title: 'Rasp: Contact Us For A Supply Chain Automation System',
    description: 'To lower your purchase costs, improve your eAuctions and automate supplier, product, and contract management, contact us today. Enter the future of procurement.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Contact Us'
      }
    }
  }
            return(
              <DocumentMeta {...meta}>
              <div>
                 {/* <!====================================text start================================>  */}
                 <div>
                  <div className="contactbg">
                    <div className="container text-center mt-5">
                      <h1><hr id="arcus" />Get In Touch</h1>
                    </div>
                    <div className="container mt-5">
                      <div className="row reverse">
                        <div className="col-md-12 col-lg-5 ">
                          <h4 className="mt-3 font-weight-bold">Australia</h4>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col">
                              <label className="clr small">Rasp Pty Ltd</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1"><i className="fas fa-home clr3 fa-fw" /></div>
                            <div className="col clr ">
                              <label className="clr small">122 Arthur Street</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              <label className="clr small"> North Sydney, NSW 2060</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-phone fa-flip-horizontal clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small">Call Us: +61 (0)2 8090 9060</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-globe clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small"> Web: rasp.com.au</label>
                            </div>
                          </div>
                          <h4 className="mt-4">
                            <label className="mt-3 font-weight-bold">New Zealand</label>
                          </h4>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              <label className="clr small">Rasp Pty Ltd</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              <label className="clr small">Auckland phone number</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              <label className="clr small">serviced out of Sydney office</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-phone fa-flip-horizontal clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small">Call Us: +64 (0)9 871 0660</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-globe clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small">Web: rasp.com.au</label>
                            </div>
                          </div>
                          <h4 className="mt-4 font-weight-bold">UK</h4>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              <label className="clr small">Trade Interchange Ltd</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-home clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small">Kildale House, Dukes Court</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1" />
                            <div className="col clr">
                              <label className="clr small"> Stockton on Tees TS17 9LR, UK</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-phone fa-flip-horizontal clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small">Call Us: +44 (0)333 320 9933</label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <i className="fas fa-globe clr3 fa-fw" />
                            </div>
                            <div className="col clr">
                              <label className="clr small">Web: <a href="https://www.tradeinterchange.com" rel="noopener noreferrer" target="_blank" >www.tradeinterchange.com</a></label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-7">

						{/* <!====================================form start================================>  */}

                         <ContactusForm />

						{/* <!====================================form end================================>  */}

                          <div className="row">
                            <div className="col-lg-12 text-center">
                            {/* <ToastContainer hideProgressBar  /> */}
                              {/* <button type="button" className="btn btn-primary mt-3 px-lg-5">Thank You - Your Message has Been Received</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                      {/* <!==============================blog Cards end==============================>   */}
                   <CtaStripe />
              </div>
            </DocumentMeta>
        )
    }

    export default ContactUs
