import { useEffect } from "react";

const useSendinblue = (clientKey, trackingEmail = null) => {
    useEffect(() => {
        if (!clientKey) {
            console.error("Sendinblue client key is missing.");
            return;
        }

        // Prevent duplicate script injection
        if (document.getElementById("sendinblue-js")) {
            // Track page view if script is already loaded
            window.sendinblue?.page?.();
            return;
        }

        // Initialize the `sib` object
        window.sib = window.sib || {
            equeue: [],
            client_key: clientKey,
        };

        if (trackingEmail) {
            window.sib.email_id = trackingEmail;
        }

        window.sendinblue = window.sendinblue || {};
        ["track", "trackLink", "page"].forEach((method) => {
            window.sendinblue[method] = (...args) => {
                const t = {};
                t[method] = args;
                window.sib.equeue.push(t);
            };
        });

        // Load the Sendinblue script dynamically
        const script = document.createElement("script");
        script.id = "sendinblue-js";
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://sibautomation.com/sa.js?key=${clientKey}`;
        script.onload = () => {
            // Track page view once script is loaded
            window.sendinblue?.page?.();
        };
        document.body.appendChild(script);

        return () => {
            // Optional: Cleanup if needed
            const existingScript = document.getElementById("sendinblue-js");
            if (existingScript) document.body.removeChild(existingScript);
        };
    }, [clientKey, trackingEmail]);

    // Return Sendinblue tracking methods
    return {
        track: window.sendinblue?.track || (() => {}),
        trackLink: window.sendinblue?.trackLink || (() => {}),
        page: window.sendinblue?.page || (() => {}),
    };
};

export default useSendinblue;
