import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import axios from 'axios'
import Recaptcha from 'react-recaptcha'

const { REACT_APP_SIB_API_KEY, REACT_APP_SIB_RE_CAPTCHA_KEY } = process.env;
const instance = axios.create({
  baseURL: 'https://api.sendinblue.com/v3',
  timeout: 5000,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    'api-key': REACT_APP_SIB_API_KEY
  },
  responseType: 'json'
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const ContactusForm = () => {
  const [showSubmitMsg, setShowSubmitMsg] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  const recaptchaLoaded = () => {
    // console.log('capcha successfully loaded');
  }

 const verifyCallback = (response) => {
    if (response) {
      console.log("verified")
      setCaptcha(true)
    }
  }

  const onSubmit = async values => {
    const {firstname, lastname, orgName, phone, email, message} = values
    await sleep(300)
    instance.post('/smtp/email',{
      sender: {name: 'RASP-Contact form', email: 'contactform@rasp.com.au'},
      to: [{email: 'info@rasp.com.au', name: 'RASP Info'}],
      replyTo: {email: 'info@rasp.com.au'},
      subject: 'Someone filled your contact us form',
      htmlContent: `Name:  ${firstname} ${lastname} <br/> Organisation: ${orgName} <br/> Phone: ${phone} <br/> Email: ${email} <br/> Message: ${message}`
    }).then(function (response) {
      console.log(response)
      setShowSubmitMsg(true)
    })
    .catch(function (error) {
      console.log(error)
    })

    // Code to create new contact
    instance.post('/contacts',{
      attributes: {
        FIRSTNAME: firstname,
        LASTNAME: lastname,
        ORGANISATION: orgName,
        PHONE: phone
      },
      updateEnabled: true,
      email: email
    }).then(function (response) {
      console.log(response)
      // window.sib.email_id = email
      localStorage.setItem("trackingEmail", email);
    })
    .catch(function (error) {
      console.log(error)
    })
    await sleep(4000)
    setShowSubmitMsg(false)
  }

  return(
  <Form
  onSubmit={onSubmit}
  validate={values => {
    const errors = {}
    if(captcha === false) {
      errors.firstname = ''
    }
    if (!values.firstname) {
      errors.firstname = 'First name is required.'
    }
    if (!values.lastname) {
      errors.lastname = 'Last name is required.'
    }
    if (!values.orgName) {
      errors.orgName = 'Organisation name is required.'
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required'
    } else if (values.phone.length < 6) {
      errors.phone = 'Enter a valid phone number'
    }
    if (!values.email) {
      errors.email = 'Email is required.'
    } else if (!values.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      errors.email = 'Enter a valid email.'
    }
    return errors
  }}
  render={({ handleSubmit, form, submitting, pristine, values }) => (
    <form onSubmit={handleSubmit}>
      <div className="row">
				<div className="col-md-4">
					<div className="form-group">
      <Field name="firstname">
        {({ input, meta }) => (
          <div>
            <input {...input} type="text" placeholder="First name" className="col-md-12" />
            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
          </div>
        )}
      </Field>
      </div>
      </div>
      <div className="col-md-4">
					<div className="form-group">
      <Field name="lastname">
        {({ input, meta }) => (
          <div>
            <input {...input} type="text" placeholder="Last name" className="col-md-12" />
            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
          </div>
        )}
      </Field>
      </div>
      </div>
      <div className="col-md-4">
      <div className="form-group">
      <Field name="phone">
        {({ input, meta }) => (
          <div>
            <input {...input} type="text" placeholder="Phone" className="col-md-12" />
            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
          </div>
        )}
      </Field>
      </div>
      </div>
      </div>
      <div className="row">
				<div className="col-md-6">
        <div className="form-group">
      <Field name="orgName">
        {({ input, meta }) => (
          <div>
            <input {...input} type="text" placeholder="Organisation Name" className="col-md-12" />
            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
          </div>
        )}
      </Field>
      </div>
      </div>
      <div className="col-md-6">
					<div className="form-group">
            <Field name="email">
              {({ input, meta }) => (
                <div>
                  <input {...input} type="email" placeholder="Email" className="col-md-12" />
                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                </div>
              )}
            </Field>
          </div>
      </div>
      </div>
      <div className="row">
				<div className="col-md-12">
        <div className="form-group">
            <Field name="message" className="form-control messagebox" component="textarea" placeholder="Message" />
      </div>
      </div>
      </div>
      <div className="row">
				<div className="col-md-6">
        <div className="form-group">
        <form action="" class="img-fluid">
						<Recaptcha
            sitekey={REACT_APP_SIB_RE_CAPTCHA_KEY}
						render="explicit"
						onloadCallback={recaptchaLoaded}
						verifyCallback={verifyCallback}
						/>
				</form>
        {!captcha &&
          <div className="buttons col-lg-12 text-center">
            <span className="error">
               Please select 'I'm not a robot' checkbox.
            </span>

          </div>
        }
          </div>
          </div>
          <div className="col-md-6 text-right">
            <button type="submit" className="btn btn-primary mt-3 px-lg-5 col-lg-10" disabled={submitting}>
              Send Message
            </button>
          </div>
          </div>

      {showSubmitMsg &&
      <div className="buttons col-lg-12 text-center">
        <button type="button" className="btn btn-primary mt-3 px-lg-5" >
          Thankyou - Your message has been received.
        </button>
      </div>
     }
    </form>
  )}
/>)
}

export default ContactusForm
